import { useEffect, useState } from "react";

import {
  LoaderFunctionArgs,
  MetaFunction,
  json,
  redirect,
} from "@remix-run/node";

import { Link, useLoaderData } from "@remix-run/react";
import { useProgress } from "@react-three/drei";

import Markdown from "react-markdown";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import SplitType from "split-type";

import { setMetaTags } from "~/lib/utils";
import { getHomePage } from "~/lib/services/home-page.server";

import {
  getLanguageFromSession,
  updateLanguageSession,
} from "~/lib/sessions.server";

import { useTranslate } from "~/hooks/useTranslate";

import { ArrowCounterClockwise, CaretRight } from "@phosphor-icons/react";

import {
  Button,
  CubeLoader,
  NewsletterMiniCard,
  PodcastMiniCard,
  ScrollArea,
  ScrollBar,
  VerticalServicesSlide,
  VerticalServicesSlider,
} from "~/components";

import { VIcon } from "~/components/icons/v-icon";

import { WatchScene } from "~/components/3d/scenes/watch-scene";
import { HeroScene } from "~/components/3d/scenes/hero-scene";
import { PressReleaseCard } from "~/components/ui/press-release-card";

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
  const supportedLangs = ["en", "de"];
  let lang = params.lang || (await getLanguageFromSession(request)).lang;
  if (!lang || !supportedLangs.includes(lang)) {
    lang = "de";
    const target = request.url.split("/")[3];
    if (target !== "undefined") {
      return redirect(`/${lang}/${target}`, {
        status: 301,
        headers: await updateLanguageSession(request, lang || "de"),
      });
    }
  }
  const url = request.url;
  const page = await getHomePage(request, lang);
  return json(
    { page, url, lang },
    {
      headers: await updateLanguageSession(request, lang || "de"),
    }
  );
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return setMetaTags(data?.url, data?.page?.seo);
};

export default function Index() {
  const { page, lang } = useLoaderData<typeof loader>();
  const { progress } = useProgress();

  const [sceneLoaded, setSceneLoaded] = useState(false);
  const [showHero, setShowHero] = useState(false);
  const [insights, setInsights] = useState<string>("newsletter");

  const insightOptions = ["newsletter", "podcast", "press"];

  const { translate } = useTranslate();

  useEffect(() => {
    const mm = gsap.matchMedia();
    gsap.registerPlugin(ScrollTrigger);

    gsap.set("#header-cover", {
      opacity: 0,
    });

    gsap.to("#intro-title", {
      scrollTrigger: {
        trigger: "#intro-section",
        start: "top center",
        end: "top top",
        scrub: true,
      },
      filter: "blur(200px)",
      opacity: 0,
    });

    gsap.to("#header-cover", {
      scrollTrigger: {
        trigger: "#intro-section",
        start: "top top",
        end: "top top",
        scrub: true,
      },
      opacity: 1,
    });

    mm.add("(min-width: 768px)", () => {
      const splitTypes = document.querySelectorAll(".intro-p");
      splitTypes.forEach((char: any) => {
        const text = new SplitType(char, { types: "words" });
        gsap.from(text.words, {
          scrollTrigger: {
            trigger: char,
            start: "top 80%",
            end: "top: 20%",
            scrub: true,
          },
          opacity: 0.2,
          stagger: 0.1,
        });
      });
    });
  }, []);

  useEffect(() => {
    const mm = gsap.matchMedia();
    mm.add("(min-width: 768px)", () => {
      if (progress === 100) {
        setShowHero(true);
        setSceneLoaded(true);
        gsap.to(".hero-scene", {
          opacity: 1,
          duration: 2,
          ease: "power2.inOut",
        });
      }
    });
  }, [progress]);

  return (
    <div className="relative">
      <section
        id="hero-section"
        className="bg-footer text-footer-foreground menu-is-dark overflow-hidden h-dvh sticky top-0 z-0"
      >
        <div className="absolute w-dvw h-dvh object-cover top-0 left-0 right-0 bottom-0">
          <div
            className={`absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center space-y-6 text-white transition-opacity ease-in-out duration-300 ${
              sceneLoaded ? "opacity-0" : "opacity-100"
            }`}
          >
            <CubeLoader />
            <span className="pl-8">{translate("loader.scene")}</span>
          </div>
          <div className="relative h-full w-full hero-scene opacity-0">
            {showHero && (
              <>
                <HeroScene />
              </>
            )}
          </div>
          {!showHero && (
            <div className="absolute top-0 left-0 right-0 bottom-0 overflow-hidden">
              <video
                autoPlay
                muted
                playsInline
                loop
                className="object-cover w-full h-full block md:hidden"
              >
                <source src="/videos/hero-video.mp4" type="video/mp4" />
              </video>
            </div>
          )}
          <div className="absolute top-0 left-0 right-0 bottom-0">
            <div className="absolute h-96 top-0 left-0 right-0 bg-gradient-to-b from-[#131319] to-transparent"></div>
            <div className="absolute h-96 bottom-0 left-0 right-0 bg-gradient-to-t from-[#131319] to-transparent "></div>
            <div
              id="intro-title"
              className="page first relative overflow-hidden"
            >
              <h1 className="w-1/2 text-[2.5rem] leading-[50px] md:leading-[120%] sm:text-[3.5rem] md:text-[5rem] text-white text-wrap">
                {page?.introTitle}
              </h1>
            </div>
          </div>
        </div>
        <div
          id="header-cover"
          className="absolute h-full w-full bg-footer"
        ></div>
      </section>
      <section
        id="intro-section"
        className="bg-footer text-footer-foreground menu-is-dark landscape:h-dvh portrait:py-12 flex flex-col justify-center relative"
      >
        <div className="container pt-6 first">
          <div className="max-w-[894px] mx-auto intro-text overflow-x-hidden">
            <h2 className="intro-p text-lg md:text-[2.625rem]">
              {page?.introSubtitle}
            </h2>
            <p className="lead mb-10 intro-p">{page?.introText}</p>
            <p className="lead intro-p">{page?.introTextPTwo}</p>
          </div>
        </div>
      </section>
      <VerticalServicesSlider>
        {page.services?.map((s, i) => (
          <VerticalServicesSlide
            key={s.title}
            index={i}
            slide={{
              title: s.title,
              description: s.description,
            }}
            className="bg-footer text-footer-foreground menu-is-dark"
          >
            {s.image && i < 1 && (
              <div className="relative">
                <img
                  className="w-full h-full object-cover object-center"
                  src={s.image}
                  alt="project"
                  title={s.title + " image"}
                  loading="lazy"
                  width={616}
                  height={506}
                />
              </div>
            )}
            {s.video && (
              <div className="w-[358px] h-[258px] md:w-[616px] md:h-[536.18px] mx-auto">
                <video autoPlay loop muted playsInline className="w-full">
                  <source src={s.video} type="video/mp4"></source>
                </video>
              </div>
            )}
            {i === 2 && (
              <div className="relative w-[358px] h-[258px] md:w-[616px] md:h-[536.18px] mx-auto">
                <WatchScene />
                <button
                  id={`${s.title}-button`}
                  aria-label={
                    translate("label.interactive").toString() || `${s.title}`
                  }
                  className="absolute bottom-2 md:bottom-6 left-1/2 -translate-x-1/2 bg-footer  py-2 px-4 text-secondary rounded-full flex items-center space-x-2"
                >
                  <ArrowCounterClockwise />
                  <span>{translate("label.interactive")}</span>
                </button>
              </div>
            )}
          </VerticalServicesSlide>
        ))}
      </VerticalServicesSlider>
      <section className="bg-footer text-footer-foreground menu-is-dark relative">
        <div className="page">
          <h2 className="mb-[60px]">{page?.experienceMainTitle}</h2>
          <div className="aspect-video relative">
            <img
              className="w-full h-full object-cover object-center"
              src={page.experienceMedia}
              alt="experience"
              title="Experience image"
              loading="lazy"
              width={1311}
              height={644}
            />
            <div className="px-4 md:px-16 py-2 md:py-10 absolute bottom-0 left-0 right-0 flex items-center justify-between backdrop-blur supports-[backdrop-filter]:bg-black/10">
              <h3 className="mb-0 text-xs md:text-[1.875rem] relative">
                {page.experienceTitle}
              </h3>
              <Link
                to={page.experienceUrl as string}
                className="hidden md:block"
              >
                <Button
                  id="experience-url"
                  aria-label="Experience Link"
                  role="button"
                >
                  {page.experienceButton}
                </Button>
              </Link>
              <VIcon className="hidden md:block w-16 h-14 absolute right-0 text-[#513E80]" />
            </div>
          </div>
          <Link to={page.experienceUrl as string} className="block md:hidden">
            <Button
              id="experience-button"
              aria-label="Experience"
              role="button"
              className="mt-6 w-full"
            >
              {page.experienceButton}
            </Button>
          </Link>
        </div>
      </section>
      <section className="bg-deep-blue text-deep-blue-foreground menu-is-dark relative">
        <div className="page">
          <div className="grid lg:grid-cols-2 gap-6 lg:gap-20">
            <h2>{page.expertiseTitle}</h2>
            <div>
              <Markdown
                components={{
                  p: ({ children }) => <p className="lead mb-16">{children}</p>,
                  strong: ({ children }) => (
                    <strong className="font-medium">{children}</strong>
                  ),
                  li: ({ children }) => (
                    <li className="mb-6 pb-6 border-b">{children}</li>
                  ),
                  a: ({ children, href }) => (
                    <a
                      className="text-secondary group flex items-center hover:no-underline"
                      href={href}
                    >
                      {children}
                      <CaretRight className="md:opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all duration-300" />
                    </a>
                  ),
                }}
              >
                {page.expertiseDescription}
              </Markdown>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-[#155743] text-foreground relative">
        <img
          className="hidden md:block absolute bottom-0 lg:top-0 lg:right-0 w-[841px] h-[375px]"
          src="/images/forward-pattern.svg"
          alt="forward pattern"
          title="Arrow pattern"
          loading="lazy"
          width={841}
          height={375}
        />
        <div className="page relative">
          <h2 className="mb-16 text-white">{page.useCasesTitle}</h2>
          <ScrollArea>
            <div className="grid md:grid-flow-col md:auto-cols-[787px] gap-6 md:gap-10 md:mb-10">
              {page.useCases?.map((useCase) => (
                <div
                  key={useCase.title}
                  className="bg-white text-black p-10 flex flex-col md:flex-row space-y-10 md:space-y-0 md:space-x-10"
                >
                  <img
                    src={useCase.image || ""}
                    alt="use case"
                    title={useCase.title || ""}
                    loading="lazy"
                    width={332}
                    height={254}
                  />
                  <div className="flex flex-col justify-between space-y-10 md:space-y-0">
                    <div>
                      <h3>{useCase.title}</h3>
                      <p className="text-black/70">{useCase.description}</p>
                    </div>
                    <Link
                      to={useCase.link_url as string}
                      className="bg-primary px-6 py-2 text-center text-white hover:bg-[#513E80] hover:no-underline"
                    >
                      {useCase.link_text}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        </div>
      </section>
      <section className="bg-footer text-footer-foreground menu-is-dark relative">
        <div className="page">
          <div className="md:flex justify-between items-center border-b pb-12 md:pb-0 mb-16">
            <h2>{page.projectsTitle}</h2>
            <Link
              to={`/${lang}/clients-and-projects`}
              className="absolute md:relative right-4 text-secondary"
            >
              {translate("btn.view.all")}
            </Link>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
            {page.projects
              ?.filter((c) => c.slug)
              .map((c, i) => (
                <Link
                  key={i}
                  to={`/${lang}/clients-and-projects/${c.slug}`}
                  className="p-4 bg-newsletter flex flex-col justify-between space-y-6 lg:aspect-[4/3] group overflow-hidden relative"
                >
                  <img
                    className="lg:absolute w-full h-full top-0 left-0  transition-all duration-300 ease-in-out group-hover:blur group-hover:saturate-0 group-hover:opacity-45 group-hover:scale-125"
                    src={(c.images && c.images[0]) || ""}
                    alt="project"
                    loading="lazy"
                    title={c.client || ""}
                    width={516}
                    height={296.46}
                  />
                  <div className="lg:absolute lg:bottom-4 lg:opacity-0 lg:translate-y-full group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-300 ease-in-out text-white">
                    <h3 className="mb-0 text-lg">
                      {i + 1 < 10 ? `0${i + 1}` : i + 1}
                    </h3>
                    <h3 className="break-words text-lg">{c.client}</h3>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </section>
      <section className="bg-background text-foreground relative">
        <div className="page">
          <h2 className="mb-16">{page.insightsTitle}</h2>
          <nav className="flex justify-between items-center border-b mb-16 overflow-hidden">
            <div className="flex items-center">
              {insightOptions.map((option, i) => (
                <button
                  id={`${option}-tab-button`}
                  key={i}
                  aria-label={option}
                  className={
                    insights === option
                      ? "text-primary font-medium p-4 hover:no-underline uppercase relative"
                      : "text-foreground font-medium p-4 hover:no-underline uppercase"
                  }
                  onClick={() => setInsights(option)}
                >
                  {option === "newsletter" && page.newsletterTitle}
                  {option === "podcast" && page.podcastTitle}
                  {option === "press" && "PRESS"}
                  {insights === option && (
                    <span className="absolute left-0 -bottom-[1px] right-0 h-[4px] w-full bg-primary block"></span>
                  )}
                </button>
              ))}
            </div>
            <Link className="hidden md:block" to={`insights/${insights}`}>
              {translate("btn.view.all")}
            </Link>
          </nav>
          <ScrollArea>
            <div className="grid grid-flow-col auto-cols-[60%] md:auto-cols-[45%] gap-6 md:gap-10">
              {insights === "newsletter" && (
                <>
                  {page.newsletters
                    ?.sort(
                      (a, b) =>
                        new Date(b.publishingDate || 0).getTime() -
                        new Date(a.publishingDate || 0).getTime()
                    )
                    .map((newsletter, i) => (
                      <NewsletterMiniCard key={i} newsletter={newsletter} />
                    ))}
                </>
              )}
              {insights === "podcast" && (
                <>
                  {page.podcasts
                    ?.sort(
                      (a, b) =>
                        new Date(b.publishingDate || 0).getTime() -
                        new Date(a.publishingDate || 0).getTime()
                    )
                    .map((podcast, i) => (
                      <PodcastMiniCard key={i} podcast={podcast} />
                    ))}
                </>
              )}
              {insights === "press" && (
                <>
                  {page.pressReleases
                    ?.sort(
                      (a, b) =>
                        new Date(b.releaseDate || 0).getTime() -
                        new Date(a.releaseDate || 0).getTime()
                    )
                    .map((release, i) => (
                      <PressReleaseCard key={i} pressRelease={release} />
                    ))}
                </>
              )}
            </div>
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        </div>
      </section>
    </div>
  );
}
