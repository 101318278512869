import dayjs from "dayjs";
import Markdown from "react-markdown";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./hover-card";
import { Info } from "@phosphor-icons/react";
import { useTranslate } from "~/hooks/useTranslate";
import { AI } from "../icons/ai";

export const PodcastMiniCard = ({ podcast, className }: any) => {
  const { translate } = useTranslate();
  return (
    <div className={className}>
      <div className="aspect-video relative overflow-hidden mb-6 ">
        <img
          className="w-full h-full object-cover object-center"
          src={podcast.cover}
          alt="newsletter"
          title={podcast.title}
          loading="lazy"
          width={597}
          height={428}
        />
        <div className="absolute bottom-4 left-1/2 -translate-x-[50%]">
          <audio controls>
            <track kind="captions" />
            <source src={podcast.audio} type="audio/mpeg" />
          </audio>
        </div>
      </div>
      <p className="text-muted-foreground mb-2">
        {dayjs(podcast.publishingDate).format("DD MMM YYYY")}
      </p>

      <Markdown
        components={{
          p: ({ children }) => (
            <div className="ai-paragraph md:w-2/3">
              <p>{children}</p>
              {podcast.isAIGen && (
                <div className="ai-icon">
                  <HoverCard>
                    <HoverCardTrigger asChild>
                      <button>
                        <AI />
                      </button>
                    </HoverCardTrigger>
                    <HoverCardContent className="text-[16px] leading-[24px] text-muted-foreground font-normal">
                      {translate("popup.insights")}
                    </HoverCardContent>
                  </HoverCard>
                </div>
              )}
            </div>
          ),
        }}
      >
        {podcast.title}
      </Markdown>
    </div>
  );
};
