import { Link, NavLink } from "@remix-run/react";
import {
  InstagramLogo,
  LinkedinLogo,
  YoutubeLogo,
} from "@phosphor-icons/react";

import { getMenuLinks } from "~/lib/utils";

import { Logo } from "../icons/logo";
import { LangToggle } from "../ui/lang-toggle";
import { useTranslate } from "~/hooks/useTranslate";

export function Footer({ lang, menus }: { lang: string; menus?: any }) {
  const { translate } = useTranslate();
  return (
    <footer className="bg-footer text-footer-foreground py-20 overflow-hidden">
      <div className="container">
        <div className="flex flex-col space-y-6 lg:flex-row lg:space-y-0 items-center justify-between mb-20 text-footer-foreground">
          <div className="flex flex-col lg:flex-row items-center space-y-6 space-x-0 lg:space-y-0 lg:space-x-[14px]">
            <Link
              to="/"
              role="link"
              aria-label="Link to home page"
              className="text-footer-foreground"
            >
              <Logo />
            </Link>
            <LangToggle lang={lang} color="white" />
          </div>
          <nav className="nav">
            <ul role="menu">
              {menus &&
                getMenuLinks(menus, "header").map(
                  ({
                    title,
                    slug,
                    navTitle,
                  }: {
                    title: string;
                    slug: string;
                    navTitle: string;
                  }) => (
                    <li key={slug} role="menuitem">
                      <NavLink
                        to={lang + "/" + slug}
                        className={({ isActive }) => (isActive ? "active" : "")}
                        aria-label={navTitle}
                      >
                        {navTitle}
                      </NavLink>
                    </li>
                  )
                )}
            </ul>
          </nav>
          <div className="flex flex-row items-center space-x-[14px]">
            <Link
              to="https://www.linkedin.com/company/ovrlay"
              target="_blank"
              title="OVRLAY LinkedIn"
              rel="noopener noreferrer"
              className="text-footer-foreground"
              role="link"
              aria-label="OVRLAY LinkedIn"
            >
              <LinkedinLogo weight="fill" size={24} />
            </Link>
            <Link
              to="https://www.instagram.com/ovrlay.world"
              target="_blank"
              title="OVRLAY Instagram"
              rel="noopener noreferrer"
              className="text-footer-foreground"
              role="link"
              aria-label="OVRLAY Instagram"
            >
              <InstagramLogo weight="fill" size={24} />
            </Link>
            <Link
              to="https://www.youtube.com/channel/UCLVdSRFD6HD54fcl6IRrHKA"
              target="_blank"
              title="OVRLAY Youtube"
              rel="noopener noreferrer"
              className="text-footer-foreground"
              role="link"
              aria-label="OVRLAY Youtube"
            >
              <YoutubeLogo weight="fill" size={24} />
            </Link>
          </div>
        </div>
        <div className="pt-8 border-t border-footer-muted flex flex-col items-center justify-between gap-4 text-[0.875rem] md:flex-row">
          <p className="text-balance text-center leading-[150%] text-footer-muted md:text-left m-0">
            {translate("footer.ovrlay.info")}
          </p>
          <nav>
            <ul
              role="menu"
              className="flex flex-col md:flex-row items-center gap-4"
            >
              {menus &&
                getMenuLinks(menus, "footer").map(
                  ({ title, slug, navTitle }) => (
                    <li key={slug} role="menuitem">
                      <NavLink
                        to={lang + "/" + slug}
                        className={({ isActive }) =>
                          isActive
                            ? "font-medium text-white leading-[150%] normal-case"
                            : "font-normal leading-[150%] text-footer-muted normal-case"
                        }
                        aria-label={navTitle}
                      >
                        {navTitle}
                      </NavLink>
                    </li>
                  )
                )}
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
}
