import { useEffect } from "react";

export const GTAG = ({ gtag }: { gtag: string | undefined }) => {
  useEffect(() => {
    const scriptAdded =
      document.getElementById("gtm") || document.getElementById("gtm-script");
    if (scriptAdded) return;

    const gtmScript = document.createElement("script");
    gtmScript.id = "gtm";

    gtmScript.innerHTML = `
        (function(w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.id = 'gtm-script';
          j.src =
              'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${gtag}');`;

    document.head.appendChild(gtmScript);

    return () => {
      document.head.removeChild(gtmScript);
    };
  }, [gtag]);

  return (
    <noscript suppressHydrationWarning>
      <iframe
        title="Google Tag Manager"
        src={`https://www.googletagmanager.com/ns.html?id=${gtag}`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      ></iframe>
    </noscript>
  );
};
