import { useGLTF } from "@react-three/drei";

export function CarModel(props: any) {
  const { nodes, materials }: any = useGLTF("/models/car.glb");
  return (
    <group {...props} dispose={null}>
      <group
        position={[1.751, 0.547, -1.621]}
        rotation={[-Math.PI / 2, 0, 1.803]}
        castShadow
        receiveShadow
      >
        <mesh
          geometry={nodes._ncl1_19.geometry}
          material={materials["P918_black.0"]}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes._ncl1_19_1.geometry}
          material={materials["black.002"]}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes._ncl1_19_2.geometry}
          material={materials.black}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes._ncl1_19_3.geometry}
          material={materials["black.001"]}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes._ncl1_19_4.geometry}
          material={materials["black.003"]}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes._ncl1_19_5.geometry}
          material={materials["black.004"]}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes._ncl1_19_6.geometry}
          material={materials["black_matt.007"]}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes._ncl1_19_7.geometry}
          material={materials["black_matt.008"]}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes._ncl1_19_8.geometry}
          material={materials["black_matt.009"]}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes._ncl1_19_9.geometry}
          material={materials["black_matt.011"]}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes._ncl1_19_10.geometry}
          material={materials.black_matt}
        />
        <mesh
          geometry={nodes._ncl1_19_11.geometry}
          material={materials["black_matt.001"]}
        />
        <mesh
          geometry={nodes._ncl1_19_12.geometry}
          material={materials["black_matt.003"]}
        />
        <mesh
          geometry={nodes._ncl1_19_13.geometry}
          material={materials["black_matt.004"]}
        />
        <mesh
          geometry={nodes._ncl1_19_14.geometry}
          material={materials["black_matt.005"]}
        />
        <mesh
          geometry={nodes._ncl1_19_15.geometry}
          material={materials["black_matt.006"]}
        />
        <mesh
          geometry={nodes._ncl1_19_16.geometry}
          material={materials["black_matt.013"]}
        />
        <mesh
          geometry={nodes._ncl1_19_17.geometry}
          material={materials["black_matt.021"]}
        />
        <mesh
          geometry={nodes._ncl1_19_18.geometry}
          material={materials["black_matt.014"]}
        />
        <mesh
          geometry={nodes._ncl1_19_19.geometry}
          material={materials["black_matt.015"]}
        />
        <mesh
          geometry={nodes._ncl1_19_20.geometry}
          material={materials["black_matt.016"]}
        />
        <mesh
          geometry={nodes._ncl1_19_21.geometry}
          material={materials["black_matt.017"]}
        />
        <mesh
          geometry={nodes._ncl1_19_22.geometry}
          material={materials["black_matt.018"]}
        />
        <mesh
          geometry={nodes._ncl1_19_23.geometry}
          material={materials["black_matt.020"]}
        />
        <mesh
          geometry={nodes._ncl1_19_24.geometry}
          material={materials["black_shiny.007"]}
        />
        <mesh
          geometry={nodes._ncl1_19_25.geometry}
          material={materials.P918_paint}
        />
        <mesh
          geometry={nodes._ncl1_19_26.geometry}
          material={materials["918weissach_wheel"]}
        />
        <mesh
          geometry={nodes._ncl1_19_27.geometry}
          material={materials["chrome.027"]}
        />
        <mesh
          geometry={nodes._ncl1_19_28.geometry}
          material={materials.d_glass}
        />
        <mesh geometry={nodes._ncl1_19_29.geometry} material={materials.dash} />
        <mesh
          geometry={nodes._ncl1_19_30.geometry}
          material={materials.glass}
        />
        <mesh
          geometry={nodes._ncl1_19_31.geometry}
          material={materials["glass.001"]}
        />
        <mesh
          geometry={nodes._ncl1_19_32.geometry}
          material={materials.interior_white}
        />
        <mesh
          geometry={nodes._ncl1_19_33.geometry}
          material={materials["o_glass.001"]}
        />
        <mesh
          geometry={nodes._ncl1_19_34.geometry}
          material={materials.o_glass}
        />
        <mesh
          geometry={nodes._ncl1_19_35.geometry}
          material={materials["o_glass.002"]}
        />
        <mesh
          geometry={nodes._ncl1_19_36.geometry}
          material={materials.orange}
        />
        <mesh
          geometry={nodes._ncl1_19_37.geometry}
          material={materials["orange.001"]}
        />
        <mesh
          geometry={nodes._ncl1_19_38.geometry}
          material={materials.plate}
        />
        <mesh
          geometry={nodes._ncl1_19_39.geometry}
          material={materials["plate.001"]}
        />
        <mesh
          geometry={nodes._ncl1_19_40.geometry}
          material={materials["r_glass.001"]}
        />
        <mesh
          geometry={nodes._ncl1_19_41.geometry}
          material={materials.r_glass}
        />
        <mesh
          geometry={nodes._ncl1_19_42.geometry}
          material={materials.backlight_glass}
        />
        <mesh
          geometry={nodes._ncl1_19_43.geometry}
          material={materials["red.002"]}
        />
        <mesh geometry={nodes._ncl1_19_44.geometry} material={materials.red} />
        <mesh
          geometry={nodes._ncl1_19_45.geometry}
          material={materials["red.001"]}
        />
        <mesh
          geometry={nodes._ncl1_19_46.geometry}
          material={materials.silver}
        />
        <mesh
          geometry={nodes._ncl1_19_47.geometry}
          material={materials["tire_mat3.003"]}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes._ncl1_19_48.geometry}
          material={materials["tire_mat3.002"]}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes._ncl1_19_49.geometry}
          material={materials.tire_mat3}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes._ncl1_19_50.geometry}
          material={materials["tire_mat3.001"]}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes._ncl1_19_51.geometry}
          material={materials["white_matt.001"]}
        />
        <mesh
          geometry={nodes._ncl1_19_52.geometry}
          material={materials.white_matt}
        />
        <mesh
          geometry={nodes._ncl1_19_53.geometry}
          material={materials["white_matt.002"]}
        />
        <mesh
          geometry={nodes._ncl1_19_54.geometry}
          material={materials["white_matt.003"]}
        />
        <mesh geometry={nodes._ncl1_19_55.geometry} material={materials.wood} />
      </group>
    </group>
  );
}

useGLTF.preload("/models/car.glb");
