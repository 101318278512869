import { useMemo } from "react";

import { MeshStandardMaterial } from "three";
import { useGLTF } from "@react-three/drei";

import { OwatchModel } from "./owatch";
import { PurseModel } from "./purse";
import { CarModel } from "./car";

export function HeroModel(props: any) {
  const { nodes, materials }: any = useGLTF("/models/heroscene.glb");
  const podiumMat = useMemo(
    () =>
      new MeshStandardMaterial({
        metalness: 0,
        roughness: 1,
        color: "#cbcbcb",
        toneMapped: false,
      }),
    []
  );
  return (
    <group {...props} dispose={null}>
      <group>
        <mesh
          position={[0, 5.2, -10]}
          rotation={[Math.PI / 2, 0, 0]}
          receiveShadow
        >
          <planeGeometry args={[10, 50]} />
          <meshBasicMaterial color="#fff" />
        </mesh>
      </group>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <group position={[500, -2510.9, 0]} rotation={[0, 0, Math.PI / 2]}>
          <group position={[4.571, 0, 0]}>
            <mesh
              geometry={nodes.Mesh060.geometry}
              material={materials.M_Glass}
            />
            <mesh
              geometry={nodes.Mesh060_1.geometry}
              material={materials.M_DoorFrame}
            />
          </group>
        </group>

        <mesh
          geometry={nodes.Ceiling_Lights_L.geometry}
          material={materials.M_CeilingLights}
          position={[-839.091, 1006.258, 8.187]}
        />
        <mesh
          geometry={nodes.Floor.geometry}
          material={materials.FloorMat}
          position={[-45.133, -508.324, -0.091]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <mesh
          geometry={nodes.Floor_Out.geometry}
          material={materials.PavementMat}
          position={[-899.828, 398.547, -1.588]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <mesh
          geometry={nodes.Wall_Out.geometry}
          material={materials.WallMat}
          position={[-1000, 500, 87.781]}
          rotation={[0, 0, Math.PI]}
          castShadow
          receiveShadow
        />
      </group>
      <CarModel rotation={[0, -1.9, 0]} position={[-0.4, -0.09, 2]} />
      <OwatchModel position={[0.4, 0.4, 4.6]} scale={1.0} />
      <PurseModel position={[-1.4, -0.16, -0.4]} scale={1.3} />
      <mesh
        geometry={nodes.Podium.geometry}
        material={podiumMat}
        position={[1, 0.001, 4.5]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.01}
        castShadow
        receiveShadow
      />
    </group>
  );
}

useGLTF.preload("/models/heroscene.glb");
