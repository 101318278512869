import { Suspense, useEffect, useRef, useState } from "react";

import { Vector3 } from "three";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import {
  AccumulativeShadows,
  AdaptiveDpr,
  Environment,
  PerspectiveCamera,
  RandomizedLight,
} from "@react-three/drei";

import { Autofocus, Bloom, EffectComposer } from "@react-three/postprocessing";

import { gsap } from "gsap";

import { HeroModel } from "../models/hero";

const CameraRig = () => {
  const v = new Vector3();
  useFrame((state) => {
    const t = state.clock.elapsedTime;
    state.camera.position.lerp(
      v.set(0.5 + Math.sin(t / 6), 0.8620633156946368, 7),
      0.0005
    );
    state.camera.lookAt(0, 0, 0);
  });
  return (
    <PerspectiveCamera
      position={[0, 0.8620633156946368, 7]}
      fov={60}
      makeDefault
    />
  );
};

const Effects = () => {
  const { camera, set } = useThree();
  const [config, setConfig] = useState({
    focusRange: 0.02,
    bokehScale: 8,
    smoothTime: 0.5,
    width: 512,
    height: 512,
    target: new Vector3(0.3, 0.623, 4.652),
  });
  const [blurred, setBlurred] = useState(false);
  const autoFocusRef = useRef<any>(null);

  useEffect(() => {
    if (!blurred) {
      setConfig({
        ...config,
        focusRange: 0.1,
        bokehScale: 2,
      });
      gsap.to(camera.position, {
        x: 1,
        y: 0.8620633156946368,
        z: 7,
        duration: 1.5,
        ease: "power2.inOut",
        onStart: () => {
          set({
            frameloop: "always",
          });
        },
      });
    }
    if (blurred) {
      setConfig({
        ...config,
        focusRange: 0.001,
        bokehScale: 20,
      });
      gsap.to(camera.position, {
        x: 1,
        y: 0.8620633156946368,
        z: 9,
        duration: 1.5,
        ease: "power2.inOut",
        onComplete: () => {
          set({
            frameloop: "never",
          });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blurred]);

  const onWindowScroll = () => {
    if (window.scrollY > 100) {
      setBlurred(true);
    } else {
      setBlurred(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onWindowScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", onWindowScroll);
    };
  }, []);

  return (
    <EffectComposer multisampling={8}>
      <Bloom
        mipmapBlur
        radius={0.6}
        luminanceThreshold={1}
        luminanceSmoothing={0.9}
        height={300}
        intensity={6}
      />

      <Autofocus ref={autoFocusRef} {...config} />
    </EffectComposer>
  );
};

export const HeroScene = () => {
  return (
    <Canvas shadows performance={{ min: 0.1 }} gl={{ antialias: false }}>
      <CameraRig />
      <directionalLight
        position={[-5, 5, -8]}
        intensity={0.1}
        castShadow
        shadow-mapSize={2048}
        shadow-bias={-0.001}
      >
        <orthographicCamera
          attach="shadow-camera"
          args={[-8.5, 8.5, 8.5, -8.5, 0.1, 20]}
        />
      </directionalLight>
      <Environment
        preset="city"
        background
        blur={0.1}
        backgroundIntensity={0.4}
      />
      <Suspense fallback={null}>
        <HeroModel />
        <AccumulativeShadows
          position={[0, 0.01, 0]}
          frames={100}
          alphaTest={0.9}
          scale={12}
        >
          <RandomizedLight
            amount={8}
            radius={10}
            ambient={0.5}
            position={[1, 5, -1]}
          />
        </AccumulativeShadows>
      </Suspense>
      <AdaptiveDpr pixelated />
      <Effects />
    </Canvas>
  );
};
