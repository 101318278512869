import { useEffect, useState } from "react";

import { type Params } from "@remix-run/react";
import { type ClassValue, clsx } from "clsx";

import { twMerge } from "tailwind-merge";

import {
  MenuEntityResponseCollection,
  MenuEntity,
  PageEntity,
} from "./graphql/generated";

import { Seo } from "./types/seo";

declare global {
  interface Window {
    ENV: {
      STRAPI_URL: string;
      Web3API_URL: string;
    };
  }
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function isBrowser() {
  return typeof window !== "undefined";
}

export function getEnv() {
  return isBrowser() ? window.ENV : process.env;
}

export const canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

// Expose some environment variables to the client
export function getBrowserEnvironment() {
  const { STRAPI_URL, GTAG, Web3API_URL } = process.env;
  return {
    STRAPI_URL,
    GTAG,
    Web3API_URL,
  };
}

let hydrating = true;
/**
 * Returns a boolean indicating whether the component has finished hydrating.
 *
 * @return {boolean} True if the component has finished hydrating, false otherwise.
 */
export function useHydrated(): boolean {
  const [hydrated, setHydrated] = useState(() => !hydrating);
  useEffect(() => {
    hydrating = false;
    setHydrated(true);
  }, []);
  return hydrated;
}

/**
 * Checks if a given value is a boolean.
 *
 * @param {any} value - The value to check.
 * @return {boolean} True if the value is a boolean, false otherwise.
 */
export function isBoolean(value: any): value is boolean {
  return typeof value === "boolean";
}

/**
 * Checks if a given value is a number.
 *
 * @param {any} value - The value to check.
 * @return {boolean} True if the value is a number, false otherwise.
 */
export function isNumber(value: any): value is number {
  return typeof value === "number" && !Number.isNaN(value);
}

/**
 * Checks if a given value is a string.
 *
 * @param {any} value - The value to check.
 * @return {boolean} True if the value is a string, false otherwise.
 */
export function isString(value: any): value is string {
  return typeof value === "string";
}

/**
 * Checks if a given value is a function.
 *
 * @param {any} value - The value to check.
 * @return {boolean} True if the value is a function, false otherwise.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(value: any): value is Function {
  return typeof value === "function";
}

/**
 * Generates a slug from a given string by converting it to lowercase,
 * replacing special characters with spaces, splitting it into words,
 * removing empty words, and joining the words with hyphens.
 *
 * @param {string} string - The input string to generate a slug from.
 * @return {string} The generated slug.
 */
export function slugify(string: string): string {
  return string
    .toLowerCase()
    .replace(/[ .':]/g, " ")
    .split(" ")
    .filter(Boolean)
    .join("-");
}

export function getMenuLinks(
  menus: MenuEntityResponseCollection,
  name: string
): {
  title: string;
  slug: string;
  navTitle: string;
}[] {
  if (!menus) return [];

  if (!menus.data) return [];

  const menu = menus.data.find(
    (menu: MenuEntity) => menu?.attributes?.name === name
  );
  if (!menu) return [];

  if (!menu.attributes?.pages?.data) return [];

  return menu.attributes.pages.data.map((page: PageEntity) => ({
    title: page?.attributes?.Title as string,
    slug: page?.attributes?.Slug as string,
    navTitle: page?.attributes?.NavTitle as string,
  }));
}

export const setCanonical = (
  originalUrl: string | null | undefined
): string | undefined => {
  if (!originalUrl) {
    return undefined;
  }
  let updatedUrl = originalUrl;
  if (updatedUrl.includes("/en/") && updatedUrl.includes("/insights/")) {
    updatedUrl = originalUrl.replace("/en/", "/de/");
  }
  return updatedUrl;
};

export const setMetaTags = (
  url: string | null | undefined,
  seo: Seo | null | undefined,
  disableIndexing = false
): Record<string, unknown>[] => {
  const href = url?.includes("http") ? url.replace("http", "https") : url;
  return [
    {
      name: "robots",
      content: disableIndexing ? "noindex, nofollow" : "index, follow",
    },
    {
      name: "googlebot",
      content: disableIndexing
        ? "noindex, nofollow"
        : "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
    },
    {
      name: "bingbot",
      content: disableIndexing
        ? "noindex, nofollow"
        : "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
    },
    { title: seo?.title },
    { name: "description", content: seo?.description },
    { tagName: "link", rel: "canonical", href: setCanonical(href) },
    { property: "og:type", content: "website" },
    { property: "og:site_name", content: "OVRLAY" },
    { property: "og:title", content: seo?.title },
    { property: "og:url", content: href },
    { property: "og:description", content: seo?.description },
    { property: "og:image", content: seo?.image },
    { name: "twitter:title", content: seo?.title },
    { name: "twitter:description", content: seo?.description },
    { name: "twitter:image", content: seo?.image },
    { name: "twitter:card", content: "summary_large_image" },
  ];
};

export const handleFetchError = (response: Response, message: string) => {
  const { status, statusText } = response;
  throw new Response("Error Loading NFTs. Please try again later.", {
    status,
    statusText,
  });
};

export const handleLanguageUnsupportedError = (
  params: Params<string>,
  url: string
) => {
  const supported = ["en", "de"];
  const { lang } = params;
  const updatedUrl = url.split("/");
  updatedUrl[3] = "en";
  const en = updatedUrl.join("/");
  updatedUrl[3] = "de";
  const de = updatedUrl.join("/");
  if (!lang || !supported.includes(lang)) {
    throw new Response(
      JSON.stringify({ message: "Content not found", url: { en, de } }),
      {
        status: 405,
        statusText: "Requested language is not supported",
      }
    );
  }
};
