import { useGLTF } from "@react-three/drei";

export function PurseModel(props: any) {
  const { nodes, materials }: any = useGLTF("/models/purse.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Purse.geometry}
        material={materials.M_Purse}
        position={[2.205, 0.552, 3.799]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.018}
        castShadow
        receiveShadow
      />
    </group>
  );
}

useGLTF.preload("/models/purse.glb");
