export const VIcon = ({ className = "w-16 h-14" }: { className?: string }) => {
  return (
    <svg
      viewBox="0 0 65 62"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={className}
    >
      <path d="M65 42.5136C58.6058 39.4488 52.2195 36.376 45.8325 33.3021L43.7322 32.2957C43.6022 32.235 43.4733 32.1651 43.3284 32.0872L40.8157 30.8023L43.3049 29.5347C43.4349 29.4649 43.534 29.4132 43.6406 29.3697L46.8522 27.8247C52.7981 24.9594 58.9407 22.008 64.9924 19.1083C64.9848 14.4196 64.9848 9.73185 64.9848 5.05222L64.9848 -6.74248e-10L-1.33057e-06 30.8195L64.9848 61.7606L64.9848 57.3422C64.9848 52.4023 64.9848 47.4625 64.9924 42.5227L65 42.5136Z" />
    </svg>
  );
};
