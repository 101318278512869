import { Link, NavLink } from "@remix-run/react";
import { Menu } from "lucide-react";

import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetClose,
  Button,
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "..";

import { getMenuLinks } from "~/lib/utils";
import { useTranslate } from "~/hooks/useTranslate";

import { Logo } from "../icons/logo";
import { useState } from "react";

export function SideNav({ lang, menus }: { lang: string; menus: any }) {
  const [open, setOpen] = useState<boolean>(false);
  const { translate } = useTranslate();
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger>
        <Menu className="sidenav__trigger text-muted-foreground" />
      </SheetTrigger>
      <SheetContent
        side="left"
        className="sidenav flex flex-col justify-between bg-black/50 backdrop-blur-lg supports-[backdrop-filter]:bg-black/10 border-r border-muted-foreground/40 shadow-lg"
      >
        <div>
          <SheetHeader>
            <SheetTitle className="sidenav_title">
              <div className="sidenav_logo__container">
                <Link to="/" role="link" aria-label="Link to home page">
                  <Logo />
                  <span className="text-white">OVRLAY</span>
                </Link>
              </div>
            </SheetTitle>
          </SheetHeader>
          <nav>
            <ul role="menu" aria-orientation="vertical">
              {menus &&
                getMenuLinks(menus, "header").map(
                  ({
                    title,
                    slug,
                    navTitle,
                  }: {
                    title: string;
                    slug: string;
                    navTitle: string;
                  }) => {
                    const submenu = getMenuLinks(menus, slug);
                    if (submenu.length > 0) {
                      return (
                        <Accordion key={slug} type="single" collapsible>
                          <AccordionItem
                            value={slug}
                            key={slug}
                            className="border-none [&[data-state=closed]>h3]:mb-0"
                          >
                            <AccordionTrigger className="text-[18px] uppercase hover:no-underline p-0 font-medium text-muted-foreground transition-colors hover:text-primary gap-2">
                              <li className="no-underline">{navTitle}</li>
                            </AccordionTrigger>
                            <AccordionContent>
                              {submenu.map(({ title, slug, navTitle }) => (
                                <li role="menuitem" className="py-2" key={slug}>
                                  <NavLink
                                    to={lang + "/" + slug}
                                    className={({ isActive }) =>
                                      isActive ? "active" : ""
                                    }
                                    aria-label={navTitle}
                                    onClick={() => setOpen(false)}
                                  >
                                    {navTitle}
                                  </NavLink>
                                </li>
                              ))}
                            </AccordionContent>
                          </AccordionItem>
                        </Accordion>
                      );
                    }
                    return (
                      <li role="menuitem" key={slug}>
                        <NavLink
                          to={lang + "/" + slug}
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          aria-label={navTitle}
                          onClick={() => setOpen(false)}
                        >
                          {navTitle}
                        </NavLink>
                      </li>
                    );
                  }
                )}
            </ul>
          </nav>
        </div>
        <Link
          to="https://outlook.office365.com/owa/calendar/OVRLAY1@ovrlay.world/bookings/"
          target="_blank"
          rel="noreferrer"
          role="link"
          title="OVRLAY Outlook"
        >
          <Button
            className="mb-20 w-full whitespace-normal"
            role="button"
            aria-label="Schedule a call"
          >
            {translate("btn.schedule.a.call")}
          </Button>
        </Link>
      </SheetContent>
    </Sheet>
  );
}
