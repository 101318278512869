import { Link } from "@remix-run/react";

import dayjs from "dayjs";
import Markdown from "react-markdown";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./hover-card";
import { Info } from "@phosphor-icons/react";
import { useTranslate } from "~/hooks/useTranslate";
import { AI } from "../icons/ai";

export const NewsletterMiniCard = ({ newsletter, className }: any) => {
  const { translate } = useTranslate();

  return (
    <div className={className}>
      <div className="aspect-video relative overflow-hidden mb-6 ">
        <img
          className="w-full h-full object-cover object-center"
          src={newsletter.heroImage as string}
          alt="newsletter"
          title={newsletter.title}
          loading="lazy"
          width={597}
          height={428}
        />
      </div>
      <p className="text-muted-foreground mb-2">
        {dayjs(newsletter.publishingDate).format("DD MMM YYYY")}
      </p>
      <Markdown
        components={{
          p: ({ children }) => (
            <div className="ai-paragraph md:w-2/3">
              <Link
                to={`insights/newsletter/${newsletter.link}`}
                className="normal-case text-foreground font-normal"
              >
                <p>{children}</p>
              </Link>
              {newsletter.isAIGen && (
                <div className="ai-icon">
                  <HoverCard>
                    <HoverCardTrigger asChild>
                      <button>
                        <AI />
                      </button>
                    </HoverCardTrigger>
                    <HoverCardContent className="text-[16px] leading-[24px] text-muted-foreground font-normal">
                      {translate("popup.insights")}
                    </HoverCardContent>
                  </HoverCard>
                </div>
              )}
            </div>
          ),
        }}
      >
        {newsletter.title}
      </Markdown>
    </div>
  );
};
