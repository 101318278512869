import { useMatches } from '@remix-run/react';
import { Term } from '~/lib/types/term';

export const useTranslate = () => {
  const matches = useMatches();
  const { rootTerms }: any = matches.find((match) => match.id === 'root')
    ?.data as Term[];

  const mapedTerms = new Map<string | undefined, string | number | undefined>();
  rootTerms.forEach((term: Term) => {
    mapedTerms.set(term.key, term.value);
  });

  const translate = (key: string) => {
    if (!rootTerms) return key;
    return mapedTerms?.get(key) || key;
  };

  return { translate };
};
