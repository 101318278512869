import dayjs from "dayjs";

import { Button } from "./button";

import { PressRelease } from "~/lib/types";
import { useTranslate } from "~/hooks/useTranslate";

export const PressReleaseCard = ({
  pressRelease: { title, description, releaseDate, download },
}: {
  pressRelease: PressRelease;
}) => {
  const { translate } = useTranslate();
  return (
    <div className="flex flex-col md:flex-row gap-6 [&:not(:last-child)]:pb-6 [&:not(:last-child)]:mb-6 md:[&:not(:last-child)]:mb-10 [&:not(:last-child)]:border-b">
      <div className="flex-auto space-y-4">
        <div className="text-muted-foreground">
          {dayjs(releaseDate).format("DD MMM YYYY")}
        </div>
        <h2 className="ai-paragraph lg:max-w-[617px]">{title}</h2>
        <p>{description}</p>
        <div>
          <Button variant="outline" className="dark:border-secondary">
            <a
              href={download}
              download
              className="dark:text-secondary hover:no-underline"
              rel="noreferrer"
            >
              {translate("btn.download")}
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};
