import { DoubleSide, Vector3 } from "three";

import {
  Circle,
  ContactShadows,
  Environment,
  Image,
  Line,
  OrbitControls,
  PerspectiveCamera,
  PresentationControls,
} from "@react-three/drei";

import { OwatchModel } from "../models/owatch";
import { Canvas } from "@react-three/fiber";

const Ecliptic = ({
  xRadius = 1,
  zRadius = 1,
  dashed = false,
  color = "white",
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  castShadow = false,
  receiveShadow = false,
}: {
  xRadius?: number;
  zRadius?: number;
  dashed?: boolean;
  color?: string;
  position?: [number, number, number];
  rotation?: [number, number, number];
  castShadow?: boolean;
  receiveShadow?: boolean;
}) => {
  const points = [];
  for (let index = 0; index < 64; index++) {
    const angle = (index / 64) * 2 * Math.PI;
    const x = xRadius * Math.cos(angle);
    const z = zRadius * Math.sin(angle);
    points.push(new Vector3(x, 0, z));
  }
  points.push(points[0]);
  return (
    <Line
      points={points}
      lineWidth={1}
      color={color}
      dashed={dashed}
      dashSize={0.01}
      gapSize={0.01}
      position={position}
      rotation={rotation}
      castShadow={castShadow}
      receiveShadow={receiveShadow}
    />
  );
};

const Watch = () => {
  return (
    <group>
      <OwatchModel />
    </group>
  );
};

export const WatchScene = () => {
  return (
    <Canvas shadows>
      <Environment preset="city" />
      <ambientLight intensity={0.5} />
      <spotLight
        position={[10, 10, 10]}
        angle={0.15}
        penumbra={1}
        shadow-mapSize={2048}
        castShadow
      />
      <PerspectiveCamera makeDefault position={[0, 0.2, 1.5]} />
      <PresentationControls
        global
        config={{ mass: 2, tension: 500 }}
        snap={{ mass: 4, tension: 1500 }}
        rotation={[0, 0.3, 0]}
        polar={[0, 0]}
        azimuth={[-Infinity, Infinity]}
      >
        <group>
          <Watch />
          <mesh
            position={[0, -0.3, 0]}
            rotation={[-Math.PI / 2, 0, 0]}
            receiveShadow
          >
            <planeGeometry args={[10, 10]} />
            <meshBasicMaterial color="#2F2F35" />
          </mesh>
          <Image
            url="/images/token.svg"
            position={[0.5, 0.5, 0]}
            side={DoubleSide}
            scale={[0.1, 0.1]}
          />
          <Ecliptic
            xRadius={0.5}
            zRadius={0.5}
            color="#FFFFFF"
            dashed
            position={[0, 0.4, 0]}
          />
          <Ecliptic
            xRadius={0.5}
            zRadius={0.5}
            color="#4E4E59"
            position={[0, -0.15, 0]}
          />
          <Circle
            args={[0.5, 32]}
            position={[0, -0.15, 0]}
            rotation={[-Math.PI / 2, 0, 0]}
            castShadow
            receiveShadow
          >
            <meshBasicMaterial color="#222229" />
          </Circle>
          <OrbitControls
            enableZoom={false}
            enablePan={false}
            enableRotate={false}
            autoRotate
            autoRotateSpeed={0.8}
          />
        </group>
      </PresentationControls>
      <ContactShadows resolution={1024} position={[0, -0.14, 0]} />
      <ContactShadows resolution={1024} position={[0, -0.29, 0]} />
    </Canvas>
  );
};
