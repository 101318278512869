import { NewsletterForm } from "../forms";

export function NewsletterWidget() {
  return (
    <section className="bg-newsletter text-newsletter-foreground py-20 menu-is-dark">
      <div className="container">
        <NewsletterForm />
      </div>
    </section>
  );
}
