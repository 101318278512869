import { useGLTF } from "@react-three/drei";

export function OwatchModel(props: any) {
  const { nodes, materials }: any = useGLTF("/models/owatch.glb");
  return (
    <group {...props} dispose={null}>
      <group
        position={[0.007, 0.353, 0.121]}
        rotation={[2.629, 0, Math.PI / 2]}
        scale={0.001}
      >
        <group
          position={[-0.063, 0, -26.095]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        >
          <mesh
            geometry={nodes.Mesh_21.geometry}
            material={materials.M_Watch}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.Mesh_21_1.geometry}
            material={materials.M_WatchGlass}
            castShadow
            receiveShadow
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/models/owatch.glb");
