export const AI = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.41406 16.0928L11.404 10.2063C11.4201 10.1746 11.4527 10.1546 11.4882 10.1546C11.5248 10.1546 11.558 10.1757 11.5736 10.2087L14.3522 16.0928M9.8986 14.6082H12.6202M17.0739 16.0928V10.1546M23.5068 17.0825H24.744M8.90891 23.7629V25"
        stroke="#6934DA"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M2.23711 17.0824H1"
        stroke="#6934DA"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.92188 2.23711V1"
        stroke="#6934DA"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M23.5156 8.91748H24.7527"
        stroke="#6934DA"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.835 23.7629V25M23.5155 13.1237H24.7526M12.6289 23.7629V25M2.23711 13.1237H1M12.6289 2.23711V1"
        stroke="#6934DA"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M2.23711 8.91748H1"
        stroke="#6934DA"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8281 2.23711V1"
        stroke="#6934DA"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M18.5666 4.95874H7.18516C5.81869 4.95874 4.71094 6.06649 4.71094 7.43297V18.8144C4.71094 20.1809 5.81869 21.2886 7.18516 21.2886H18.5666C19.9331 21.2886 21.0408 20.1809 21.0408 18.8144V7.43297C21.0408 6.06649 19.9331 4.95874 18.5666 4.95874Z"
        stroke="#6934DA"
        strokeWidth="1.2"
      />
    </svg>
  );
};
