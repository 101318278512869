import { useState } from "react";
import { Link, useLocation } from "@remix-run/react";

import { Button } from "./button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./dropdown-menu";

import { CaretDown, CaretUp } from "@phosphor-icons/react";

export const LangToggle = ({
  lang,
  color,
}: {
  lang: string;
  color: string;
}) => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  const getPathname = (l: string) => {
    return l + "/" + pathname.split("/").slice(2).join("/");
  };

  return (
    <DropdownMenu onOpenChange={setOpen} modal={false}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className={`flex space-x-2 w-16 h-[46px] text-${color}`}
        >
          <span className="uppercase">{lang}</span>
          <span>
            {open ? <CaretUp size="12px" /> : <CaretDown size="12px" />}
          </span>
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem asChild>
          <Link
            to={getPathname("de")}
            className="dropdown_menu_link"
            reloadDocument
          >
            DE
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <Link
            to={getPathname("en")}
            className="dropdown_menu_link"
            reloadDocument
          >
            EN
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
