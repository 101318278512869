import { useLocation } from "@remix-run/react";
import Markdown from "react-markdown";

import { ContactForm } from "../forms";

export const ContactWidget = ({ contactData }: any) => {
  const location = useLocation();
  const show = !location.pathname.includes("/contact");

  if (!show) {
    return null;
  }

  return (
    <section className="dark-section menu-is-dark bg-contact text-contact-foreground">
      <div className="page">
        <div className="grid md:grid-cols-2 gap-6 md:gap-20 mb-6 md:mb-28">
          <div className="order-last md:order-first">
            <h3 className="hidden md:block">{contactData?.title}</h3>
            <Markdown
              components={{
                h5: ({ children }) => <h4 className="mt-12">{children}</h4>,
                a: ({ href, children }) => (
                  <a
                    href={href}
                    className="text-secondary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {children}
                  </a>
                ),
              }}
            >
              {contactData?.content}
            </Markdown>
          </div>
          <div>
            <h3 className="block md:hidden">{contactData?.title}</h3>
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
};
