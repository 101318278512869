import { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "@remix-run/react";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { CaretDown } from "@phosphor-icons/react";
import { Logo } from "../icons/logo";

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui";

import { SideNav } from "./sidenav";
import { LangToggle } from "../ui/lang-toggle";

import { useTranslate } from "~/hooks/useTranslate";
import { getMenuLinks } from "~/lib/utils";

export function Header({ lang, menus }: { lang: string; menus: any }) {
  const { pathname } = useLocation();
  const { translate } = useTranslate();
  const menu = useRef<HTMLDivElement>(null);
  const [menuMode, setMenuMode] = useState<"light" | "dark">("light");
  const [open, setOpen] = useState<string | null>(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  }, []);

  useEffect(() => {
    const sections: HTMLDivElement[] = gsap.utils.toArray("section");
    const menuHeight = menu.current?.offsetHeight;
    sections.forEach((section, i) => {
      const isDark = section.className.includes("menu-is-dark");
      ScrollTrigger.create({
        trigger: section,
        start: "top " + menuHeight,
        end: "bottom " + menuHeight,
        onEnter: () => setMenuMode(isDark ? "dark" : "light"),
        onEnterBack: () => setMenuMode(isDark ? "dark" : "light"),
        toggleActions: "restart none none reverse",
        immediateRender: false,
        preventOverlaps: true,
      });
    });
    return () => {
      ScrollTrigger.killAll();
    };
  }, [pathname]);

  return (
    <header ref={menu} className={`${menuMode === "dark" ? "dark" : "light"}`}>
      <div className="header_container">
        <div className="header_container__left">
          <Link
            className="logo"
            to={`/${lang}`}
            role="link"
            aria-label="Link to home page"
          >
            <Logo />
          </Link>
        </div>
        <nav>
          <ul role="menu" aria-orientation="horizontal">
            {menus &&
              getMenuLinks(menus, "header").map(
                ({
                  title,
                  slug,
                  navTitle,
                }: {
                  title: string;
                  slug: string;
                  navTitle: string;
                }) => {
                  const submenu = getMenuLinks(menus, slug);
                  if (submenu.length > 0) {
                    return (
                      <DropdownMenu
                        key={slug}
                        modal={false}
                        onOpenChange={(open) => setOpen(open ? slug : null)}
                      >
                        <DropdownMenuTrigger asChild>
                          <li
                            key={slug}
                            role="menuitem"
                            className="pointer-cursor"
                          >
                            <a
                              className="uppercase flex items-center gap-2"
                              role="button"
                              aria-label={navTitle}
                              href={slug}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              {navTitle}
                              <span>
                                <CaretDown
                                  size="12px"
                                  className={`translate-transform duration-150 ease-linear ${
                                    open ? "rotate-180" : ""
                                  }`}
                                />
                              </span>
                            </a>
                          </li>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          {submenu.map(({ slug, navTitle }) => (
                            <DropdownMenuItem asChild key={slug}>
                              <Link
                                to={lang + "/" + slug}
                                className="dropdown_menu_link"
                                reloadDocument
                              >
                                {navTitle}
                              </Link>
                            </DropdownMenuItem>
                          ))}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    );
                  }
                  return (
                    <li key={slug} role="menuitem">
                      <NavLink
                        to={lang + "/" + slug}
                        className={({ isActive }) => (isActive ? "active" : "")}
                        preventScrollReset={false}
                        aria-label={navTitle}
                      >
                        {navTitle}
                      </NavLink>
                    </li>
                  );
                }
              )}
          </ul>
        </nav>
        <div className="header_container__right">
          <LangToggle lang={lang} color="foreground" />
          <Link
            to="https://outlook.office365.com/owa/calendar/OVRLAY1@ovrlay.world/bookings/"
            target="_blank"
            rel="noreferrer"
            title="OVRLAY Outlook"
            role="button"
            aria-label="Book a call"
          >
            <Button className="hidden xl:block">
              {translate("btn.schedule.a.call")}
            </Button>
          </Link>
          <div className="flex xl:hidden pl-2">
            <SideNav lang={lang} menus={menus} />
          </div>
        </div>
      </div>
    </header>
  );
}
